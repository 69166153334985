<template>
    <TPTable :show-only-uuid="item.uuid" @refresh="$emit('refresh')"></TPTable>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    props: ["text", "uuid"],
    computed: {
        ...mapGetters(["offerByUuid"]),
        item() {
            return this.offerByUuid(this.uuid);
        }
    },
    components: {
        TPTable: () => import("@/views/tagProcessor/Output/Table/Table.vue")
    },
    data() {
        return {
            //
        };
    }
};
</script>
